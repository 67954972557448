// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import "./blog.css";
// import { client } from '../../sanity/client';
// import Navbar from '../../components/navbar/navbar';
// import Footer from '../../components/footer/footer';
// import SeoHelmet from '../../components/SEO/SeoHelmet';
// import Whatsappbutton from '../../components/whatsappbutton/whatsappbutton';
// import Backtotopbtn from '../../components/backtotop/backtotop';
// import Chatsection from '../../components/chatsection/chatsection';
// // import { portfolioPageSchema } from '../../components/SEO/SeoSchemas';
// import ReactGA from 'react-ga4';

// const POSTS_QUERY = `*[
//   _type == "post"
//   && defined(slug.current)
// ]|order(publishedAt desc)[0...12]{_id, title, slug, publishedAt}`;

// function BlogPage() {
//   const [posts, setPosts] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     ReactGA.send({ hitType: "pageview", page: window.location.pathname });

//     // Add schema markup to the page
//     const script = document.createElement('script');
//     script.type = 'application/ld+json';
//     // script.text = JSON.stringify(portfolioPageSchema); // Reusing portfolio schema - consider creating a Blog schema if needed
//     document.head.appendChild(script);

//     // Cleanup
//     return () => {
//       document.head.removeChild(script);
//     };
//   }, []);


//   useEffect(() => {
//     client.fetch(POSTS_QUERY)
//       .then((data) => {
//         setPosts(data);
//         setIsLoading(false);
//       })
//       .catch((error) => {
//         console.error(error);
//         setIsLoading(false);
//       });
//   }, []);

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       <SeoHelmet
//         title="Blog - Insights and Articles from StatixPro"
//         description="Read the latest articles and insights on digital marketing, branding, and web development from the experts at StatixPro."
//         keywords="digital marketing blog, branding articles, web development insights, marketing tips, SEO news"
//         canonicalUrl="https://statix.pro/blog"
//         ogImage="https://statix.pro/blog-og.jpg"
//         ogType="website"
//       />
//       <div className="logo-and-nav">
//         <Navbar />
//       </div>
//       <main className="container mx-auto min-h-screen max-w-3xl p-8 mt-16"> {/* Added mt-16 for spacing */}
//         <h1 className="text-4xl font-bold mb-8 text-center">Our Latest Thoughts & Insights</h1>
//         <ul className="flex flex-col gap-y-4">
//           {posts.map((post) => (
//             <li className="hover:underline" key={post._id}>
//               <Link to={`/blog/${post.slug.current}`}>
//                 <h2 className="text-xl font-semibold">{post.title}</h2>
//                 <p className="text-gray-600">{new Date(post.publishedAt).toLocaleDateString()}</p>
//               </Link>
//             </li>
//           ))}
//         </ul>
//       </main>
//       <Footer />
//       <Whatsappbutton />
//       <Backtotopbtn />
//       <Chatsection />
//     </div>
//   );
// }

// export default BlogPage;

import { useEffect, useState } from "react";
import { BuilderComponent, builder } from "@builder.io/react";

// Replace with your Public API Key.
builder.init("8b91cdf6979343948c24459f7c7601b7");

export default function Page() {
  const [announcement, setAnnouncement] = useState(null);

  useEffect(() => {
    builder
      .get("announcement-bar", {
        userAttributes: {
          // To allow targeting different announcements at different pages (URLs)
          urlPath: window.location.pathname,
        },
      })
      .toPromise()
      .then((announcementBar) => setAnnouncement(announcementBar));
  }, []);

  return (
    <>
      {/* Put your header here. */}
    
      <BuilderComponent model="announcement-bar" content={announcement} />
      {/* Put the rest of your page here. */}
    </>
  );
}
